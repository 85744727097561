import React, {useState, useEffect} from 'react';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    CircularProgress, Button, Modal, Box, TextField, MenuItem, TablePagination, FormControlLabel, Checkbox, Tooltip, Avatar, IconButton
} from '@mui/material';
import dayjs from 'dayjs';
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {IDiscount} from "../../utils/redux/model/discount.model";
import {getListOfActiveOrganizationAdminAPI} from "../../utils/redux/store/discountSlice";
import {IOrganization} from "../../utils/redux/model/organization.model";
import {AiFillEdit} from "react-icons/ai";
import {toast} from "react-toastify";

const OrganizationManagementAdmin = () => {
    const dispatch = useAppDispatch();
    const organizations: IOrganization[] = useAppSelector(state => state.discount.entities);
    const loading = useAppSelector(state => state.discount.loading);
    const [page, setPage] = useState(0); // Page number
    const [rowsPerPage, setRowsPerPage] = useState(20); // Rows per page
    const fetchCoupons = () => {
        dispatch(getListOfActiveOrganizationAdminAPI({limit: rowsPerPage, offset: page * rowsPerPage}));
    };
    const handleShowUpdateModal = () => {
        toast.warning("under development")
    }
    useEffect(() => {
        fetchCoupons();
    }, [page, rowsPerPage]);

    const handlePageChange = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when changing rows per page
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Organization Management
            </Typography>
            {loading ? (
                <CircularProgress sx={{mt: 4}}/>
            ) : (
                <TableContainer component={Paper} sx={{mt: 4}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Avatar</TableCell>
                                <TableCell>Organization Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizations.map((organization) => (
                                <TableRow key={organization.id}>
                                    <TableCell>
                                        {organization.id}
                                    </TableCell>
                                    <TableCell>
                                        <Avatar src={organization.avatar!}/>
                                    </TableCell>
                                    <TableCell>{organization.orgName}</TableCell>
                                    <TableCell>{organization.description}</TableCell>
                                    <TableCell>{dayjs.unix(organization.createdDate!).format('YYYY-MM-DD')}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={handleShowUpdateModal}>
                                            <AiFillEdit/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={100} // This should be replaced with the total number of records from your backend
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableContainer>
            )}
        </Container>
    );
};

export default OrganizationManagementAdmin;


