import React from 'react';
import {Link, useParams} from "react-router-dom";
import {Button, Card, Typography} from "@mui/material";
import {IoArrowBack} from "react-icons/io5";
import {useTranslation} from "react-i18next";
import OrganizationUserListTokenDollarUsed from "./organization/organization-user-list_token_dollar_used";

const DollarTokenUsagePerUserPerFeatureOverTime = () => {
    const {orgId} = useParams();
    const {t} = useTranslation();
    return (
        <div className="row mx-0">
            <Button
                component={Link} // Use component instead of LinkComponent
                to="/organization"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>
            <Card variant="elevation" className="row mx-0 col-12 col-lg-12 mx-auto p-4 mt-5">
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('reports.title')}</Typography>
                </div>
                <div className="row mx-0 col-12 align-items-center mt-3">
                    <div className="col-12 col-lg-6">
                        <OrganizationUserListTokenDollarUsed/>
                    </div>
                    <div className="col-12 col-lg-6">2</div>
                </div>
            </Card>
        </div>
    );
};

export default DollarTokenUsagePerUserPerFeatureOverTime;
