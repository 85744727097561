import React, {useEffect, useState} from 'react';
import {Box, Button, Card, Modal, TextField, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {toast} from "react-toastify";
import {acceptInviteToOrganizationAPI, acceptInviteToOrganizationExistUserAPI, getOrganizationByEmailTokenAPI, rejectInviteToOrganizationAPI} from "../../../utils/redux/store/organizationSlice";
import {IOrganization} from "../../../utils/redux/model/organization.model";

const InviteeVerifyExistUser = () => {
    const dispatch = useAppDispatch()
    const {inviteeEmailToken} = useParams()
    const orgFromEmailToken: IOrganization = useAppSelector(state => state.organization.orgFromEmailToken)
    const handleAcceptInvitation = () => {
        dispatch(acceptInviteToOrganizationExistUserAPI({email_token: inviteeEmailToken!}))
            .finally(() => {
                setTimeout(() => {
                    window.location.href = '/login'
                }, 3000)

            })
    }
    const handleRejectInvitation = () => {
        dispatch(rejectInviteToOrganizationAPI({email_token: inviteeEmailToken!}))
    }
    useEffect(() => {
        dispatch(getOrganizationByEmailTokenAPI({email_token: inviteeEmailToken!}))
    },[])
    if (!inviteeEmailToken) {
        toast.warning("invalid token.")
        window.location.href = '/login'
    }
    return (
        <div className="row mx-0 col-12 mt-5">
            <Card className="col-12 col-lg-5 p-3 mx-auto text-center rounded-3 border-0 shadow-none mt-5">
                <Typography variant="h3">
                    You've been invited to join the organization, <strong>"{orgFromEmailToken?.orgName}"</strong>., on Ahura's Ed Tools application.
                    <br/>
                    Would you like to accept the invitation?
                </Typography>
                <div className="col-12  align-items-center mt-3">
                    <Button className="w-167 m-2" variant="contained" color="primary" onClick={handleAcceptInvitation}>Accept</Button>
                    <Button component={Link} to="/login" className="w-167 hi-50 m-2" variant="contained" color="secondary"
                            onClick={handleRejectInvitation}>Reject</Button>
                </div>
            </Card>
        </div>
    );
};

export default InviteeVerifyExistUser;
