import React from 'react';
import {Avatar, Card, Typography} from "@mui/material";

const ToolCardLanding = (props: IToolCardLanding) => {
    return (
        <div className="row mx-0 col-12 text-center-w shadow-sm">
            <Card className="col-12 toolscard align-content-center"
                  sx={{
                      textAlign: 'center',
                      borderRadius: '16px',
                      border: '.5px solid #e0e0e0',
                      boxShadow: 'none',
                      height: '300px',
                  }}
            >
                <Avatar
                    sx={{
                        width: 70,
                        height: 70,
                        margin: '0 auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        padding: '10px',
                    }}
                    src={props.icon}
                >
                </Avatar>
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 'bold',
                        marginTop: 2
                    }}
                >
                    {props.title}
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{marginTop: 1}}
                >
                    {props.desc}
                </Typography>
            </Card>
        </div>
    );
};

export default ToolCardLanding;

export interface IToolCardLanding {
    title: string,
    desc: string,
    icon: any,
}
