import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {checkValidate} from "../../utils/redux/store/authSlice";
import {setToken} from "../../utils/localstorage/localStorage";
import {CircularProgress, Typography} from "@mui/material";

const LoginByGoogle = () => {
    const dispatch = useAppDispatch();
    const {access_token} = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        if (access_token) {
            setToken(access_token)
            dispatch(checkValidate())
                .then((res) => {
                    navigate("/tools")
                })
                .catch(() => {
                    navigate("/login")
                })
        }
    }, [])

    return (
        <div className="text-center mt-5">
            <CircularProgress size={100}/>
            <br/>
            <Typography className="text-center mt-4" variant='h2' component='h1'>Processing Request</Typography>
            <Typography className="text-center mt-4" variant='h2' component='h1'>Please Wait ...</Typography>
        </div>
    );
};

export default LoginByGoogle;
