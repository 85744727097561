// src/store/promptSlice.ts
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import axios from "axios";
import {cleanEntity} from "../reducers/entity.utils";
import {ILessonPlan, ILessonPlanResponse} from "../model/lessonplan.model";
import {ICounselingPlan, ICounselingPlanResponse} from "../model/counselingplan.model";
import {IActivityIdeaPlan, IActivityIdeaPlanResponse} from "../model/activityIdeaplan.model";
import {IQuestionGenerator} from "../model/questiongenerator.model";


const initialState: EntityState<IAccount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/prompts';

export const getEntity = createAsyncThunk(
    'prompts/fetch_entity',
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<IAccount>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)

export const lessonPlanAPI = createAsyncThunk(
    'prompts/post_lessonPlanAPI',
    async (entity: ILessonPlan, thunkAPI) => {
        const requestUrl = `${apiUrl}/lessonPlan`;
        const result = axios.post<ILessonPlanResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const counselingPlanAPI = createAsyncThunk(
    'prompts/post_counselingPlanAPI',
    async (entity: ICounselingPlan, thunkAPI) => {
        const requestUrl = `${apiUrl}/counselingPlan`;
        const result = axios.post<ICounselingPlanResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const activityIdeaPlanAPI = createAsyncThunk(
    'prompts/post_activityIdeaPlanAPI',
    async (entity: IActivityIdeaPlan, thunkAPI) => {
        const requestUrl = `${apiUrl}/activityIdeaPlan`;
        const result = axios.post<IActivityIdeaPlanResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const pdfSummarizerAPI = createAsyncThunk(
    'prompts/post_pdfSummarizerAPI',
    async (entity: File[], thunkAPI) => {
        const requestUrl = `${apiUrl}/pdfSummarizer`;
        const result = axios.post<ILessonPlanResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const pdfToPPTXAPI = createAsyncThunk(
    'prompts/post_pdfToPPTXAPI',
    async (entity: { files: File[], numberOfSlides: number, additionalContext: string, youtubeUrl: string }, thunkAPI) => {
        const requestUrl = `${apiUrl}/pdfToPPTX`;

        // Create a FormData object to send files
        const formData = new FormData();
        for (let i = 0; i < entity.files.length; i++) {
            formData.append('files', entity.files[i]);
        }
        formData.append('numberOfSlides', String(entity.numberOfSlides));
        formData.append('additionalContext', entity.additionalContext);
        formData.append('youtubeUrl', entity.youtubeUrl);

        try {
            const response = await axios.post(requestUrl, formData, {
                responseType: 'blob',  // Handle binary data (PPTX file)
            });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // Set the download attribute with a default filename
            link.setAttribute('download', 'generated_presentation.pptx');
            document.body.appendChild(link);
            link.click();

            // Cleanup: remove the link after triggering the download
            document.body.removeChild(link);

            return response.data;  // You may return the response data or some success message

        } catch (error: any) {
            // Handling the error using thunkAPI
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    },
    {
        serializeError: (error: any) => {
            return {
                message: error.message,
                response: error.response?.data,
            };
        },
    }
);
export const SummarizerAPI = createAsyncThunk(
    'prompts/post_SummarizerAPI',
    async (entity: { files: File[], numberOfSlides: number, additionalContext: string, youtubeUrl: string }, thunkAPI) => {
        const requestUrl = `${apiUrl}/summarizer`;

        // Create a FormData object to send files
        const formData = new FormData();
        for (let i = 0; i < entity.files.length; i++) {
            formData.append('files', entity.files[i]);
        }
        formData.append('numberOfSlides', String(entity.numberOfSlides));
        formData.append('additionalContext', entity.additionalContext);
        formData.append('youtubeUrl', entity.youtubeUrl);

        try {
            const response = await axios.post(requestUrl, formData);
            return response;  // You may return the response data or some success message

        } catch (error: any) {
            // Handling the error using thunkAPI
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    },
    {
        serializeError: (error: any) => {
            return {
                message: error.message,
                response: error.response?.data,
            };
        },
    }
);
export const QuestionGeneratorAPI = createAsyncThunk(
    'prompts/post_questionGenerator',
    async (entity: IQuestionGenerator, thunkAPI) => {
        const requestUrl = `${apiUrl}/questionGenerator`;
        // Create a FormData object to send files
        const formData = new FormData();
        for (let i = 0; i < entity.files.length; i++) {
            formData.append('files', entity.files[i]);
        }
        formData.append('numberOfQuestions', entity.numberOfQuestions.toString());
        formData.append('additionalContext', entity.additionalContext.toString());
        formData.append('questionType', entity.questionType.toString());
        formData.append('youtubeUrl', entity.youtubeUrl.toString());
        try {
            const response = await axios.post(requestUrl, formData);
            return response;
        } catch (error: any) {
            // Handling the error using thunkAPI
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    },
    {
        serializeError: (error: any) => {
            return {
                message: error.message,
                response: error.response?.data,
            };
        },
    }
);

export const VoiceToTextAPI = createAsyncThunk(
    'prompts/post_VoiceToTextAPI',
    async (entity: { file: File }, thunkAPI) => {
        const requestUrl = `${apiUrl}/voiceToText`;

        // Create a FormData object to send files
        const formData = new FormData();
        formData.append('audio_file', entity.file);
        try {
            const response = await axios.post(requestUrl, formData);
            return response;
        } catch (error: any) {
            // Handling the error using thunkAPI
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    },
    {
        serializeError: (error: any) => {
            return {
                message: error.message,
                response: error.response?.data,
            };
        },
    }
);
export const promptSlice = createEntitySlice({
    name: 'prompts',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(lessonPlanAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(lessonPlanAPI.fulfilled, (state, action) => {
                state.entity = action.payload.data;
                state.loading = false;
            })
            .addCase(lessonPlanAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(pdfToPPTXAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(SummarizerAPI.rejected, (state) => {
                state.loading = false;
            })
            .addMatcher(isFulfilled(getEntity, lessonPlanAPI, counselingPlanAPI, activityIdeaPlanAPI, pdfSummarizerAPI, SummarizerAPI, pdfToPPTXAPI, QuestionGeneratorAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getEntity, counselingPlanAPI, activityIdeaPlanAPI, pdfSummarizerAPI, SummarizerAPI, pdfToPPTXAPI, QuestionGeneratorAPI), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = promptSlice.actions;

export default promptSlice.reducer;
