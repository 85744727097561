import {createTheme} from '@mui/material';
import {MdKeyboardArrowDown} from 'react-icons/md';

const MUITheme = createTheme({
    // Your theme configurations here
    palette: {
        // primary: {
        //   main: '#3f51b5',
        // },
        // secondary: {
        //   main: '#f50057',
        // },
    },
    typography: {
        allVariants: {
            fontFamily: 'Plus Jakarta Sans',
            textTransform: 'unset'
        },
        h1: {
            fontSize: '48px',
            fontWeight: 700,
            lineHeight: '56.7px',
            color: 'black',
        },
        h2: {
            fontSize: '36px',
            fontWeight: 700,
            color: '#332D2D',
            lineHeight: '30.24px'
        },
        h3: {
            fontSize: '24px',
            fontWeight: 500,
            color: '#332D2D',
            lineHeight: '30.24px',
        },
        h5: {
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: 'black'
        },
        h6: {
            fontSize: '14px',
            fontWeight: 500,
            color: 'black'
        },
        subtitle1: {
            fontSize: '14px',
            color: 'black',
            fontWeight: 700,
            lineHeight: '17.64px',
            alignContent: 'center',
            marginBottom: '10px'
        },
        subtitle2: {
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '25px',
            letterSpacing: '0em',
            textAlign: 'left'
        },
        body1: {
            fontSize: '16px',
            color: '#8C8FA4',
            fontWeight: 400,
            alignItems: 'center',
            lineHeight: '24px'
        },
        caption: {
            fontSize: '13px',
            color: '#8C8FA4',
        },
        body2: {
            fontSize: '12px',
            color: '#8C8FA4',
            fontWeight: 400,
        },
    },
    components: {
        MuiIconButton: {
            variants: [
                {
                    props: {size: 'large'},
                    style: {
                        padding: '8px',
                        width: '40px',
                        height: '40px',
                        color: '#3cb0c8',
                        backgroundColor: '#FFFFFF26'
                    }
                }
            ]
        },
        MuiButton: {
            variants: [
                {
                    props: {variant: 'contained', color: 'primary'},
                    style: {
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: '700',
                        backgroundColor: 'rgb(0, 168, 154);',
                        borderRadius: '6px',
                        height: '48px',
                        padding: '14px, 24px, 14px, 24px',
                        ':hover': {
                            backgroundColor: 'rgb(0, 168, 154);'
                        }
                    }
                },
                {
                    props: {variant: 'contained', color: 'secondary'},
                    style: {
                        color: '#433605',
                        gap: '5px',
                        fontSize: '14px',
                        fontWeight: '700',
                        backgroundColor: 'rgba(0,168,154,.12)',
                        borderRadius: '10px',
                        height: '36px',
                        boxShadow: 'unset',
                        padding: '8px, 16px, 8px, 16px',
                        ':hover': {
                            backgroundColor: 'rgba(0,168,154,.12)',
                            boxShadow: 'unset'
                        }
                    }
                },
                {
                    props: {variant: 'contained', color: 'info'},
                    style: {
                        backgroundColor: '#0D0D10',
                        color: 'white',
                        fontSize: '12px',
                        width: '120px',
                        height: '50px',
                        padding: '6px, 8px, 6px, 8px',
                        borderRadius: '4px',
                        gap: '10px',
                        ':hover': {
                            backgroundColor: '#121217'

                        }
                    }
                },
                {
                    props: {variant: 'outlined', color: 'primary'},
                    style: {
                        color: 'rgb(0, 168, 154);',
                        fontSize: '14px',
                        fontWeight: '700',
                        backgroundColor: 'white',
                        borderRadius: '34px',
                        border: "1px solid rgb(0, 168, 154);",
                        height: '48px',
                        padding: '14px, 24px, 14px, 24px',
                        ':hover': {
                            backgroundColor: 'white',
                            borderColor: "black",
                        }
                    }
                },
                {
                    props: {variant: 'contained', color: 'warning'},
                    style: {
                        fontSize: '12px',
                        fontWeight: '700',
                        borderRadius: '34px',
                        borderColor: "black",
                        height: '48px',
                        padding: '14px, 24px, 14px, 24px',
                        ':hover': {
                            borderColor: "black",
                        }
                    }
                },
                {
                    props: {variant: 'outlined', color: 'secondary'},
                    style: {
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: '700',
                        backgroundColor: '#EDEDF1',
                        border: 0,
                        height: '30px',
                        padding: '14px, 24px, 14px, 24px',
                        ':hover': {
                            backgroundColor: '#EDEDF1',
                        }
                    }
                },
                {
                    props: {variant: 'text', color: 'primary'},
                    style: {
                        height: '48px',
                        backgroundColor: 'white',
                        boxShadow: 'unset',
                        borderRadius: '34px',
                        border: 'unset',
                        padding: '14px, 100px, 14px, 100px',
                        gap: '16px',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        color: '#0D0D10',
                        ':hover': {
                            backgroundColor: 'rgb(0, 168, 154)',
                            color: 'white',
                        }
                    }
                },
                {
                    props: {variant: 'text', color: 'secondary'},
                    style: {
                        color: 'black',
                        height: '48px',
                        borderRadius: '34px',
                        backgroundColor: '#EDEDF1',
                        padding: '14px, 100px, 14px, 100px',
                        gap: '16px',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px'
                    }
                }
            ]
        },
        MuiChip: {
            variants: [
                {
                    props: {variant: 'filled'},
                    style: {
                        backgroundColor: '#DAFF10',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '17.64px',
                        ':hover': {
                            backgroundColor: '#cef10e'
                        }
                    }
                }
            ]
        },
        MuiDivider: {
            variants: [
                {
                    props: {variant: 'fullWidth'},
                    style: {
                        border: '2px solid #EDEDF1'
                    }
                }
            ]
        },
        MuiCard: {
            variants: [
                {
                    props: {variant: 'elevation'},
                    style: {
                        backgroundColor: 'white',
                        boxShadow: 'unset',
                        padding: '16px',
                        borderRadius: '12px'
                    }
                },
                {
                    props: {variant: 'outlined'},
                    style: {
                        padding: '24px 32px 24px 32px',
                        borderRadius: '8px',
                        backgroundColor: '#e8e5e5',
                        // cursor: "pointer",
                        ":hover": {
                            // backgroundColor: '#3cb0c8',
                            // color: 'white!important'
                        }
                    },
                }
            ]
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: MdKeyboardArrowDown
                // there are tons of props that you can override
            },
            styleOverrides: {
                root: {
                    '.MuiSvgIcon-root': {
                        color: 'red'
                    }
                }
            },
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        backgroundColor: '#F5F6FA',
                        width: '100%',
                        height: '56px',
                        padding: '16px, 24px, 16px, 24px',
                        borderRadius: '10px',
                        border: '0.6px solid #D5D5D5',
                        gap: '24px',
                        fontSizeAdjust: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        ':hover': {
                            border: '1.5px solid #D5D5D5',
                        },
                    }
                }
            ]
        },
        MuiCheckbox: {
            defaultProps: {
                style: {
                    color: 'black',
                    borderRadius: '4px'
                }
            }
        },
        MuiTextField: {
            defaultProps: {},
            variants: [
                {
                    props: {variant: "filled"},
                    style: {
                        backgroundColor: "#F1F4F9",
                        borderRadius: '10px',
                        border: "unset",
                        '::before': {
                            content: "unset"
                        }
                    }
                },
                {
                    props: {variant: 'outlined'},
                    style: {
                        backgroundColor: '#F5F6FA',
                        border: '0.6px solid #D5D5D5',
                        borderRadius: '8px',
                        ':hover': {
                            border: '1px solid #3cb0c8',
                            borderRadius: '8px'
                        }
                    }
                }
            ],
        },
        MuiAppBar: {
            variants: [
                {
                    props: {variant: "elevation"},
                    style: {
                        backgroundColor: "#86d7ea",
                        color: "black",
                        boxShadow: "unset"
                    },
                }
            ]
        },
        MuiRating:{
            variants:[
                {
                    props: {},
                    style: {
                        color: '#F87F35',
                    }
                }
            ]
        }
    }
});

export default MUITheme;
