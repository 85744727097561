import {createAsyncThunk, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import axios from "axios";
import {defaultValue, IDiscount} from "../model/discount.model";
import {cleanEntity} from "../reducers/entity.utils";


const initialState: EntityState<IDiscount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/reports';


export const getOrganizationUserListTokenDollarUsedAPI = createAsyncThunk(
    'reports/getOrganizationUserListTokenDollarUsedAPI',
    async (organization_id: number) => {
        const requestUrl = `${apiUrl}/getOrganizationUserListTokenDollarUsed?organization_id=${organization_id}`;
        return axios.get<IDiscount[]>(requestUrl);
    },
    {serializeError: serializeAxiosError}
);

export const reportSlice = createEntitySlice({
    name: 'reports',
    initialState,

    extraReducers: (builder) => {
        builder
            // .addCase(lessonPlanAPI.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(lessonPlanAPI.fulfilled, (state, action) => {
            //     state.entity = action.payload.data;
            //     state.loading = false;
            // })
            // .addCase(lessonPlanAPI.rejected, (state) => {
            //     state.loading = false;
            // })
            .addCase(getOrganizationUserListTokenDollarUsedAPI.fulfilled, (state, action) => {
                (state as any).getOrganizationUserListTokenDollarUsedAPI = action.payload.data;
                state.loading = false;
            })
            .addCase(getOrganizationUserListTokenDollarUsedAPI.rejected, (state) => {
                state.loading = false;
            })

            // .addMatcher(isFulfilled(getEntities), (state: any, action: any) => {
            //     const {data} = action.payload;
            //     return {
            //         ...state,
            //         loading: false,
            //         entities: data,
            //     };
            // })
            .addMatcher(isPending(getOrganizationUserListTokenDollarUsedAPI), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = reportSlice.actions;

export default reportSlice.reducer;
