import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Modal, TextField, Typography} from "@mui/material";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {buyBulkSubscriptionAPI, buyBulkSubscriptionWithDiscountAPI} from "../../utils/redux/store/organizationSlice";
import {toast} from "react-toastify";
import PaymentMethodCard from "../payment/paymentMethodCard";
import {IPaymentMethod} from "../../utils/redux/model/paymentMethod.model";
import {getAllPaymentMethodAPI} from "../../utils/redux/store/paymentSlice";
import {checkCouponAPI} from "../../utils/redux/store/userSlice";
import {t} from "i18next";
import {ISubscriptionPlan} from "../../utils/redux/model/subscriptionPlan.model";

const BuyBulkSubscriptionModal = (props: { orgId: number }) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false)
    const [reqData, setReqData] = useState({basic: 0, premium: 0, couponCode: "", paymentMethod: 1, orgId: props.orgId})
    const entityCheckCouponAPI: {
        final_amount: number,
        discount_applied: number
    } = useAppSelector(state => state.user.entitycheckCouponAPI);
    const loadingCheckCouponAPI: boolean = useAppSelector(state => state.user.loadingcheckCouponAPI);
    const subscriptionPlan: ISubscriptionPlan[] = useAppSelector(state => state.subscriptionPlan.entities);
    const loadingSubscriptionPlans: boolean = useAppSelector(state => state.subscriptionPlan.loading);
    const loading: boolean = useAppSelector(state => state.organization.loading);
    const paymentMethodList: IPaymentMethod[] = useAppSelector(state => state.payment.entities);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1)
    const handleCheckCoupon = () => {
        dispatch(checkCouponAPI({
            transaction_amount: (reqData.basic * subscriptionPlan.find(x => x.name?.toLowerCase() == "basic")!.price!) +
                (reqData.premium * subscriptionPlan.find(x => x.name?.toLowerCase() == "premium")!.price!),
            coupon_code: reqData.couponCode
        }))
    }
    const handleBuyBulkSubscriptions = () => {
        if (reqData.basic === 0 && reqData.premium === 0) {
            toast.warning("Please add at least one basic/premium subscription.")
            return
        }
        if (reqData.couponCode === "") {
            dispatch(buyBulkSubscriptionAPI({...reqData, paymentMethod: selectedPaymentMethod}))
                .then((res) => {
                    const response = (res.payload as any).data
                    if ((res.payload as any).status === 200) {
                        if (typeof response === "string") {
                            window.location.href = response
                        }
                    }
                })
        } else {
            dispatch(buyBulkSubscriptionWithDiscountAPI({...reqData, paymentMethod: selectedPaymentMethod}))
                .then((res: any) => {
                    if (res.error) {
                        return
                    } else {
                        const response = (res.payload as any).data
                        if ((res.payload as any).status === 200) {
                            if (typeof response === "string") {
                                window.location.href = response
                            }
                        }
                    }


                })
        }

    }

    if (loadingSubscriptionPlans || subscriptionPlan.length === 0) {
        return <CircularProgress/>
    }
    return (
        <div>
            <Button variant="contained" color='primary' className="gap-0 mt-4 px-3"
                    startIcon={<AiOutlineShoppingCart/>}
                // disabled={loading}
                    onClick={() => setIsOpen(true)}
            >
                Buy bulk subscriptions
            </Button>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <div className="row col-5 bg-white border-0 rounded-2 p-3 mx-auto mt-5">
                    <div className="col-12">
                        <Typography className="text-center" variant="h5">How many subscription licenses would you like to purchase?</Typography>
                    </div>
                    <div className="col-6">
                        <Typography className="text-center" variant="caption">Basic</Typography>
                        <TextField
                            className="col-12"
                            placeholder="Basic"
                            type="number"
                            value={reqData.basic}
                            onChange={(e) => setReqData({...reqData, basic: Number(e.target.value)})}
                        />
                    </div>
                    <div className="col-6">
                        <Typography className="text-center" variant="caption">Premium</Typography>
                        <TextField
                            className="col-12"
                            placeholder="Premium"
                            type="number"
                            value={reqData.premium}
                            onChange={(e) => setReqData({...reqData, premium: Number(e.target.value)})}
                        />
                    </div>
                    <div className="col-12">
                        <Typography className="text-center" variant="caption">coupon Code</Typography>
                        <TextField
                            className="col-12"
                            placeholder="couponCode"
                            value={reqData.couponCode}
                            onChange={(e) => setReqData({...reqData, couponCode: e.target.value})}
                        />
                        <div className="col-3 mt-2 align-content-center">
                            <Button className="col-12" variant="contained" color="info"
                                    onClick={handleCheckCoupon}
                            >
                                {loadingCheckCouponAPI ?
                                    <CircularProgress size={24}/>
                                    :
                                    t('Wallet.checkCoupon')
                                }
                            </Button>
                        </div>
                    </div>
                    <div className="row col-12 mt-4">
                        <Typography variant="subtitle1">Payment Method</Typography>
                    </div>
                    <div className="row mx-0 col-12 mt-3">
                        {paymentMethodList.map((paymentMethod) => {
                            return (
                                <PaymentMethodCard paymentMethod={paymentMethod} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod}/>
                            )
                        })}
                    </div>
                    <div className="row mx-0 col-12 mt-3">
                        <Typography variant="caption">if you cannot find a payment option that works for you, email hi@ahuraai.com</Typography>
                    </div>
                    <div className="col-12 d-flex gap-2 mx-0 mt-4" dir="rtl">
                        <Button
                            variant="contained"
                            startIcon={<AiOutlineShoppingCart/>}
                            dir="ltr"
                            onClick={handleBuyBulkSubscriptions}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress/>
                                :
                                <React.Fragment>
                                    <div>
                                        Buy subscription =
                                    </div>
                                    <div className={entityCheckCouponAPI && "text-decoration-line-through"}>
                                        $
                                        {
                                            (reqData.basic * subscriptionPlan.find(x => x.name?.toLowerCase() == "basic")!.price!) +
                                            (reqData.premium * subscriptionPlan.find(x => x.name?.toLowerCase() == "premium")!.price!)
                                        }
                                    </div>
                                    {entityCheckCouponAPI &&
                                        <div style={{color: "red",paddingLeft:'5px'}}>
                                            ${(
                                            (reqData.basic * subscriptionPlan.find(x => x.name?.toLowerCase() == "basic")!.price!) +
                                            (reqData.premium * subscriptionPlan.find(x => x.name?.toLowerCase() == "premium")!.price!)
                                                - (entityCheckCouponAPI ? entityCheckCouponAPI.discount_applied : 0))}
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </Button>

                        <Button variant="text" color="error" onClick={() => setIsOpen(false)}>Close</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BuyBulkSubscriptionModal;
