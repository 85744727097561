import React from 'react';
import {Grid, Card, CardMedia, CardContent, Typography, Divider} from '@mui/material';
import lesson from '../../assets/instructors.jpeg'
import roleplay from '../../assets/students.jpeg'
import summerizer from '../../assets/parents.jpeg'
import ToolCardFavorites from "./ToolCardFavorites";
import Roleplay from "../../assets/tools/roleplay.jpeg";
import QuestionGenerator from "../../assets/tools/questiongenerator.jpeg";
import SlideGenerator from "../../assets/tools/slidecreator.png";
import conselingPlan from "../../assets/tools/conselingplan.jpeg";
import lessonPlanSvg from "../../assets/tools/lessonplan.jpeg";

const Favorites = () => {
    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardMedia
                        component="img"
                        height="100%"
                        image={roleplay}
                        alt="College Instructors"
                    />
                    <CardContent>
                        <Typography variant="h6" component="div">
                            College Instructors
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Role play, question extractor, case studies, writing analyzer
                        </Typography>
                        <Divider className="col-12 mt-3"/>
                        <div className="row col-12">
                            <ToolCardFavorites title="Role Play" icon={Roleplay} link="/tools/roleplay-generator"/>
                            <ToolCardFavorites title="Question Extractor" icon={QuestionGenerator} link="/tools/question-generator"/>
                            <ToolCardFavorites title="Slide Generator" icon={SlideGenerator} link="/tools/slide-generator"/>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardMedia
                        component="img"
                        height="300px"
                        image={lesson}
                        alt='Parents & High School Instructors'
                    />
                    <CardContent>
                        <Typography variant="h6" component="div">
                            Parents & High School Instructors
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Lesson plan, counseling advice, question extractor
                        </Typography>
                        <Divider className="col-12 mt-3"  />
                        <div className="row col-12">
                            <ToolCardFavorites title="Lesson Plan" icon={lessonPlanSvg} link="/tools/lesson-plan-generator"/>
                            <ToolCardFavorites title="Question Extractor" icon={QuestionGenerator} link="/tools/question-generator"/>
                            <ToolCardFavorites title="Counseling Plan" icon={conselingPlan} link="/tools/counseling-generator"/>
                        </div>
                    </CardContent>

                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardMedia
                        component="img"
                        height="100%"
                        image={summerizer}
                        alt="Students"
                    />
                    <CardContent>
                        <Typography variant="h6" component="div">
                            Students
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Summarize, question extractor, role play, writing analyzer
                        </Typography>
                        <Divider className="col-12 mt-3"  />
                        <div className="row col-12">
                            <ToolCardFavorites title="Summarizer" icon={SlideGenerator} link="/tools/slide-generator"/>
                            <ToolCardFavorites title="Question Extractor" icon={QuestionGenerator} link="/tools/question-generator"/>
                            <ToolCardFavorites title="Role Play" icon={Roleplay} link="/tools/roleplay-generator"/>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Favorites;
