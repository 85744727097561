import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {getEntities, subscribeUserToPlanAPI, subscribeUserToPlanWithDiscountAPI} from "../../../utils/redux/store/subscriptionPlanSlice";
import {ISubscriptionPlan} from "../../../utils/redux/model/subscriptionPlan.model";
import SubscriptionPlanContainer from "./subscriptionPlanContainer";
import {Button, Card, CircularProgress, Divider, TextField, Typography} from "@mui/material";
import {t} from "i18next";
import {IPaymentMethod} from "../../../utils/redux/model/paymentMethod.model";
import {getAllPaymentMethodAPI} from "../../../utils/redux/store/paymentSlice";
import {IChargeWalletResponse} from "../../../utils/redux/model/chargeWallet.model";
import {checkValidate} from "../../../utils/redux/store/authSlice";
import PaymentMethodCard from "../../payment/paymentMethodCard";
import {MdArrowBack} from "react-icons/md";
import {checkCouponAPI, reset} from "../../../utils/redux/store/userSlice";
import {convertLinuxDateToDate, daysRemaining} from "../../../utils/convertLinuxDateToDate";

const SubscriptionPlan = () => {
    const dispatch = useAppDispatch()
    const subscriptionPlans: ISubscriptionPlan[] = useAppSelector(state => state.subscriptionPlan.entities);
    const [selectedPlan, setSelectedPlan] = useState({} as ISubscriptionPlan)
    const entityCheckCouponAPI: {
        final_amount: number,
        discount_applied: number
    } = useAppSelector(state => state.user.entitycheckCouponAPI);
    const paymentMethodList: IPaymentMethod[] = useAppSelector(state => state.payment.entities);
    // const userBalance: number = useAppSelector(state => state.auth.entity.userBalance);
    const subscription: string = useAppSelector(state => state.auth.entity.subscription);
    const expirationDate: number = useAppSelector(state => state.auth.entity.expirationDate);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1)
    const [walletCoupon, setWalletCoupon] = useState("")
    const loadingCheckCouponAPI: boolean = useAppSelector(state => state.user.loadingcheckCouponAPI);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        dispatch(getEntities())
        dispatch(getAllPaymentMethodAPI())
    }, []);

    const handleSetSelectedPlan = (plan: ISubscriptionPlan) => {
        // Handle the onHandleSetSelectedPlan logic here
        setSelectedPlan(plan)
    };
    const handleCheckCoupon = () => {
        // toast.warning("under development")
        dispatch(checkCouponAPI({transaction_amount: selectedPlan.price, coupon_code: walletCoupon}))
    }
    const handlePayment = () => {
        // Handle the subscription logic here
        if (walletCoupon === "") {
            dispatch(
                subscribeUserToPlanAPI(
                    {
                        payWithWallet: false,
                        subscriptionPlanId: selectedPlan.id!,
                        paymentMethod: selectedPaymentMethod,
                    }
                )
            ).then((res) => {
                const response: IChargeWalletResponse = (res.payload as any).data
                setLoading(false)
                if ((res.payload as any).status === 200) {
                    if (typeof response.url === "string") {
                        // window.location.href = response.url
                        window.open(response.url, '_blank');// open new page
                    }
                } else {
                    window.location.reload()
                }
                dispatch(checkValidate())
            })
        } else {
            dispatch(subscribeUserToPlanWithDiscountAPI({
                payWithWallet: false,
                subscriptionPlanId: selectedPlan.id!,
                paymentMethod: selectedPaymentMethod,
                couponCode: walletCoupon,
            })).then((res) => {
                const response: IChargeWalletResponse = (res.payload as any).data
                setLoading(false)
                if ((res.payload as any).status === 200) {
                    if (response.message?.includes("Congratulation")) {
                        window.location.reload()
                        return
                    } else if (typeof response.url === "string") {
                        // window.location.href = response.url
                        window.open(response.url, '_blank');// open new page
                    }
                } else {
                    window.location.reload()
                }
                dispatch(checkValidate())
            })
        }

    };
    return (
        <Card className="row mx-0 p-4" variant="elevation">
            {subscription ?
                "You already have " + subscription + " plan activated till: " + convertLinuxDateToDate(expirationDate) + ". " + daysRemaining(expirationDate) + " days Remains"
                :
                selectedPlan.id ?
                    <React.Fragment>
                        <div className="row col-12 mx-0">
                            <div className="col-12 px-0">
                                <Button variant="contained" color="secondary" startIcon={<MdArrowBack/>}
                                        onClick={() => {
                                            setSelectedPlan({} as ISubscriptionPlan)
                                            dispatch(reset())
                                        }}
                                >
                                    Back to subscription plan
                                </Button>
                            </div>
                            <Typography variant="h2" align="center" className="p-0 m-0 mt-3 ">
                                Checkout
                            </Typography>
                            <Divider className="my-3"/>
                            <div className="col-12">
                                <Typography variant="body1">
                                    <strong>Plan:</strong> {selectedPlan.name}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Amount:</strong> ${selectedPlan.price}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Days :</strong> {selectedPlan.duration_days}
                                </Typography>
                            </div>
                            <div className="row col-12 mt-3 px-0 mx-0">
                                <div className="col-12 align-content-center">
                                    <Typography variant="subtitle1">
                                        {t('Wallet.coupon')}:
                                    </Typography>
                                </div>
                                <div className="row col-12 px-0 mx-0">
                                    <div className="col-4">
                                        <TextField
                                            type="text"
                                            autoComplete="false"
                                            value={walletCoupon}
                                            onChange={(e: any) => setWalletCoupon(e.target.value)}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-3 align-content-center">
                                        <Button className="col-12" variant="contained" color="info"
                                                onClick={handleCheckCoupon}
                                        >
                                            {loadingCheckCouponAPI ?
                                                <CircularProgress size={24}/>
                                                :
                                                t('Wallet.checkCoupon')
                                            }
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 px-0">
                            <div className="row mx-0 col-12 mt-4">
                                <Typography variant="subtitle1">Payment Method</Typography>
                            </div>
                            <div className="row mx-0 col-12 mt-3">
                                {paymentMethodList.map((paymentMethod) => {
                                    return (
                                        <PaymentMethodCard paymentMethod={paymentMethod} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod}/>
                                    )
                                })}
                            </div>

                            <div className="row mx-0 col-12 mt-3 px-0">
                                <Button className="col-12 col-lg-2 gap-2" variant="contained" color="primary"
                                        disabled={loading || selectedPlan.id === undefined}
                                        onClick={!loading ? handlePayment : () => {
                                        }}
                                >
                                    {loading ? <CircularProgress size={24}/> :
                                        t('pay')
                                    }
                                    <div className={entityCheckCouponAPI && "text-decoration-line-through"}>
                                        ${selectedPlan.price}
                                    </div>
                                    {entityCheckCouponAPI &&
                                        <div style={{color: "red"}}>
                                            ${(selectedPlan.price! - (entityCheckCouponAPI ? entityCheckCouponAPI.discount_applied : 0))}
                                        </div>
                                    }
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>

                    :
                    <React.Fragment>
                        {subscriptionPlans.map((subscriptionPlan) => {
                            return (
                                <SubscriptionPlanContainer
                                    key={subscriptionPlan.id}
                                    selectedPlan={selectedPlan}
                                    plan={subscriptionPlan}
                                    onHandleSetSelectedPlan={handleSetSelectedPlan}
                                />
                            )
                        })}
                    </React.Fragment>

            }

        </Card>
    );
};

export default SubscriptionPlan;
