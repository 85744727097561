import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel, CircularProgress } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { getOrganizationUserListTokenDollarUsedAPI } from "../../../../utils/redux/store/reportSlice";
import { useAppDispatch, useAppSelector } from "../../../../utils/redux/store";
import { useParams } from "react-router-dom";

// Define types for user data
interface OrganizationUserListTokenDollarUsedModel {
    id: number;
    name: string;
    daily: { tokens: number; dollars: number };
    weekly: { tokens: number; dollars: number };
    monthly: { tokens: number; dollars: number };
}

const OrganizationUserListTokenDollarUsed = () => {
    const { orgId } = useParams();
    const dispatch = useAppDispatch();

    // State to hold the selected period and user data
    const [selectedPeriod, setSelectedPeriod] = useState<string>('daily');
    const userData: OrganizationUserListTokenDollarUsedModel[] = useAppSelector(state => state.reports.getOrganizationUserListTokenDollarUsedAPI);
    const loading: boolean = useAppSelector(state => state.reports.loading);

    useEffect(() => {
        if (orgId) {
            dispatch(getOrganizationUserListTokenDollarUsedAPI(Number(orgId)));
        }
    }, [dispatch, orgId]); // Add orgId to the dependency array

    // Handle changes in the selected period (daily, weekly, monthly)
    const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPeriod(event.target.value);
    };

    if (loading) {
        return <CircularProgress />;
    }
    // Prepare data for the bar chart based on the selected period
    const tokenData = userData?.map((user:any) => user[selectedPeriod]?.tokens || 0);
    const dollarData = userData?.map((user:any) => user[selectedPeriod]?.dollars || 0);
    const userNames = userData?.map((user:any) => user.name);
    return (
        <div>
            <h2>Organization User - Token and Dollar Usage {orgId}</h2>

            {/* Radio group to select time period */}
            <FormControl component="fieldset">
                <RadioGroup row aria-label="time period" name="time-period" value={selectedPeriod} onChange={handlePeriodChange}>
                    <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                    <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                    <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                </RadioGroup>
            </FormControl>

            {/* Bar chart to display token and dollar usage */}
            <BarChart
                height={500}
                xAxis={[
                    {
                        id: 'userAxis',
                        data: userNames,
                        label: 'Users',
                        scaleType: 'band',
                    },
                ]}
                series={[
                    {
                        data: tokenData,
                        label: `Tokens Used (${selectedPeriod})`,
                        color: '#8884d8',
                    },
                    {
                        data: dollarData,
                        label: `Dollars Used (${selectedPeriod})`,
                        color: '#82ca9d',
                    },
                ]}
            />
        </div>
    );
};

export default OrganizationUserListTokenDollarUsed;
