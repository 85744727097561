import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {FaPlus} from "react-icons/fa";
import {t} from "i18next";
import {useState} from "react";
import {IRolePlay} from "../../../../utils/redux/model/roleplay.model";
import {FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {MdSettingsVoice} from "react-icons/md";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../../../utils/redux/store";
import {CreateRoomAPI, getListOfRoomsForCurrenUser} from "../../../../utils/redux/store/rolePlaySlice";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: 'unset',
    boxShadow: 'unset',
    p: 4,
};

export default function CreateRoomModal() {
    const dispatch = useAppDispatch()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setReqData({
            room_name: "",
            user_role: "",
            ai_role: "",
            scenario: "",
            max_guests_count: 0,
            grade_level: ""
        })
    };
    const [reqData, setReqData] = useState<IRolePlay>(
        {
            room_name: "",
            user_role: "",
            ai_role: "",
            scenario: "",
            max_guests_count: 0,
            grade_level: ""
        }
    )
    const handleCreateRoom = () => {
        if (reqData.room_name === "" || reqData.max_guests_count! <= 0 || reqData.ai_role === "" || reqData.user_role === "" || reqData.scenario === "" || reqData.grade_level === "") {
            toast.warning("please fill the form")
            return
        }
        dispatch(CreateRoomAPI(reqData)).then((res) => {
            if (!(res as any).error) {
                handleClose()
                dispatch(getListOfRoomsForCurrenUser())
            }
        })

    }

    return (
        <div>
            <Button variant="contained" color="warning"
                    startIcon={<FaPlus/>}
                    onClick={handleOpen}
            >{t('RolePlay.CreateRoom')}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="rounded" sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Create new Room
                    </Typography>
                    <div className="row mx-0">
                        <div className="col-6 mt-3">
                            <Typography variant="subtitle1">{t('CreateRoomModal.RoomName')}:</Typography>
                            <TextField
                                className="col-12"
                                placeholder={t('CreateRoomModal.RoomNamePlaceholder')}
                                value={reqData.room_name}
                                onChange={(event) => setReqData({
                                    ...reqData,
                                    room_name: event.target.value
                                })}
                            />
                        </div>
                        <div className="col-6 mt-3">
                            <Typography variant="subtitle1">{t('CreateRoomModal.max_guests_count')}:</Typography>
                            <TextField
                                className="col-12"
                                type="number"
                                InputProps={{inputProps: {min: 1}}}
                                placeholder={t('CreateRoomModal.max_guests_countPlaceholder')}
                                value={reqData.max_guests_count === 0 ? "" : reqData.max_guests_count}
                                onChange={(event) => setReqData({
                                    ...reqData,
                                    max_guests_count: Number(event.target.value)
                                })}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('CreateRoomModal.user_role')}:</Typography>
                            <TextField
                                className="col-12"
                                placeholder={t('CreateRoomModal.user_rolePlaceholder')}
                                rows={2}
                                multiline
                                value={reqData.user_role}
                                onChange={(event) => setReqData({
                                    ...reqData,
                                    user_role: event.target.value
                                })}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('CreateRoomModal.ai_role')}:</Typography>
                            <TextField
                                className="col-12"
                                placeholder={t('CreateRoomModal.ai_rolePlaceholder')}
                                rows={2}
                                multiline
                                value={reqData.ai_role}
                                onChange={(event) => setReqData({
                                    ...reqData,
                                    ai_role: event.target.value
                                })}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('CreateRoomModal.scenario')}:</Typography>
                            <TextField
                                className="col-12"
                                placeholder={t('CreateRoomModal.scenarioPlaceholder')}
                                rows={3}
                                multiline
                                value={reqData.scenario}
                                onChange={(event) => setReqData({
                                    ...reqData,
                                    scenario: event.target.value
                                })}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('LessonPlan.GradeLevel')}:</Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('LessonPlan.Grade')}</InputLabel>
                                <Select
                                    variant="outlined"
                                    id="demo-simple-select"
                                    value={reqData.grade_level!}
                                    label="Age"
                                    onChange={(event: SelectChangeEvent) => setReqData({
                                        ...reqData,
                                        grade_level: event.target.value
                                    })}
                                >
                                    <MenuItem value="Elementary">{t('LessonPlan.Elementary')}</MenuItem>
                                    <MenuItem value="Middle School">{t('LessonPlan.MiddleSchool')}</MenuItem>
                                    <MenuItem value="High School">{t('LessonPlan.HighSchool')}</MenuItem>
                                    <MenuItem value="College">{t('LessonPlan.College')}</MenuItem>
                                    <MenuItem value="Grad School">{t('LessonPlan.GradSchool')}</MenuItem>
                                    <MenuItem value="New professional">{t('LessonPlan.Newprofessional')}</MenuItem>
                                    <MenuItem value="Expert professional">{t('LessonPlan.Expertprofessional')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="row mx-0 col-12 mt-3">
                            <div className="col-6">
                                <Button variant="contained" color="warning" className="col-12"
                                        onClick={() => handleClose()}>Cancel</Button>
                            </div>
                            <div className="col-6">
                                <Button variant="contained" color="primary" className="col-12"
                                        onClick={handleCreateRoom}>Create</Button>

                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
