import {ReducersMapObject} from '@reduxjs/toolkit';
import authReducer from './store/authSlice';
import promptReducer from "./store/promptSlice";
import promptHistoryReducer from "./store/promptHistorySlice";
import adminReducer from "./store/adminSlice";
import organizationReducer from "./store/organizationSlice";
import rolePlayReducer from "./store/rolePlaySlice";
import discountReducer from "./store/discountSlice";
import userReducer from "./store/userSlice";
import subscriptionPlanReducer from "./store/subscriptionPlanSlice";
import paymentReducer from "./store/paymentSlice";
import organizationInvitationReducer from "./store/organizationInvitationSlice";
import reportReducer from "./store/reportSlice";


export const sharedReducers: ReducersMapObject = {
    auth: authReducer,
    user: userReducer,
    prompt: promptReducer,
    payment: paymentReducer,
    promptHistory: promptHistoryReducer,
    admin: adminReducer,
    organization: organizationReducer,
    organizationInvitation: organizationInvitationReducer,
    roleplay: rolePlayReducer,
    discount: discountReducer,
    subscriptionPlan: subscriptionPlanReducer,
    reports: reportReducer
};

