import React, {useState, useEffect} from 'react';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    CircularProgress, Button, Modal, Box, TextField, MenuItem, TablePagination, FormControlLabel, Checkbox, Tooltip, Avatar, IconButton
} from '@mui/material';
import dayjs from 'dayjs';
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {IDiscount} from "../../utils/redux/model/discount.model";
import {getListOfActiveOrganizationAdminAPI, getListOfUsersAPI} from "../../utils/redux/store/discountSlice";
import {IOrganization} from "../../utils/redux/model/organization.model";
import {AiFillEdit} from "react-icons/ai";
import {toast} from "react-toastify";
import {IUser} from "../../utils/redux/model/user.model";
import {SiOpenaccess} from "react-icons/si";

const UserManagementAdmin = () => {
    const dispatch = useAppDispatch();
    const organizations: IUser[] = useAppSelector(state => state.discount.entities);
    const loading = useAppSelector(state => state.discount.loading);
    const [page, setPage] = useState(0); // Page number
    const [rowsPerPage, setRowsPerPage] = useState(20); // Rows per page
    const fetchCoupons = () => {
        dispatch(getListOfUsersAPI({limit: rowsPerPage, offset: page * rowsPerPage}));
    };
    const handleShowUpdateModal = () => {
        toast.warning("under development")
    }
    const handleShowAssignRoleModal = () => {
        toast.warning("under development")
    }
    useEffect(() => {
        fetchCoupons();
    }, [page, rowsPerPage]);

    const handlePageChange = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when changing rows per page
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                User Management
            </Typography>
            {loading ? (
                <CircularProgress sx={{mt: 4}}/>
            ) : (
                <TableContainer component={Paper} sx={{mt: 4}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Avatar</TableCell>
                                <TableCell>Full Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>isActive</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Actions</TableCell>
                                {/*<TableCell>Last Login Date</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizations.map((organization) => (
                                <TableRow key={organization.id}>
                                    <TableCell>
                                        {organization.id}
                                    </TableCell>
                                    <TableCell>
                                        <Avatar src={organization.avatar!}/>
                                    </TableCell>
                                    <TableCell>{organization.fullName}</TableCell>
                                    <TableCell>{organization.email}</TableCell>
                                    <TableCell>{organization.language}</TableCell>
                                    <TableCell>{organization.role}</TableCell>
                                    <TableCell>{organization.isActive ? "true" : "false"}</TableCell>
                                    <TableCell>{dayjs.unix(organization.createdDate!).format('YYYY-MM-DD')}</TableCell>
                                    {/*<TableCell>{dayjs.unix(organization.lastLogin!).format('YYYY-MM-DD')}</TableCell>*/}
                                    <TableCell>
                                        <IconButton onClick={handleShowUpdateModal}>
                                            <AiFillEdit/>
                                        </IconButton>
                                        <Tooltip title="Assign Site admin Role to this account">
                                            <IconButton className="rounded-1 p-1" style={{height: '35px'}}
                                                        onClick={() => handleShowAssignRoleModal()}>
                                                <SiOpenaccess/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={100} // This should be replaced with the total number of records from your backend
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableContainer>
            )}
        </Container>
    );
};

export default UserManagementAdmin;


