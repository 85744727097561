import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {BarChart} from '@mui/x-charts/BarChart';
import {Switch, Typography} from "@mui/material";

const UserStatistic = () => {
    const {userId} = useParams();
    const [showDollar, setShowDollar] = useState(true)
    // Sample data for the chart
    const data = [
        {feature: 'lesson plan', tokens: 1500, dollars: 200},
        {feature: 'rolePlay', tokens: 3000, dollars: 400},
        {feature: 'counseling plan', tokens: 2500, dollars: 350},
        {feature: 'activity idea', tokens: 2000, dollars: 900},
        {feature: 'pdf generator', tokens: 2000, dollars: 300},
    ];

    return (
        <div className="row mx-0">

            <div className="col-12 d-flex align-items-center">
                <Typography variant="h2">Usage Statistic</Typography>
            </div>
            <div className="col-12 d-flex align-items-center mt-3">
                <Switch checked={showDollar} onChange={() => setShowDollar(!showDollar)}/>
                <Typography variant="h3" color="textSecondary">
                    {showDollar ? 'Dollars' : 'Token'}
                </Typography>
            </div>
            <BarChart
                height={600}
                xAxis={[
                    {
                        id: 'featureAxis',
                        data: data.map((item) => item.feature),
                        label: 'Feature',
                        scaleType: 'band',
                    },
                ]}
                series={showDollar ? [
                        {
                            data: data.map((item) => item.dollars),
                            label: 'Dollar Used',
                            color: 'rgb(0, 168, 154)',
                        },
                    ] :
                    [
                        {
                            data: data.map((item) => item.tokens),
                            label: 'Tokens Used',
                            color: 'rgb(0, 168, 154)',
                        },
                    ]}
            />
        </div>
    );
};

export default UserStatistic;
