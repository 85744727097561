import React from 'react';
import {Box, Button, Modal, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../../utils/redux/store";
import {deleteOrganizationAPI, getListOfCurrentUserJoinedOrganizationAPI} from "../../utils/redux/store/organizationSlice";
import {isNumber} from "lodash";
import {toast} from "react-toastify";

const DeleteOrganization = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {id} = useParams()
    const handleDeleteOrganization = () => {
        if (!isNumber(Number(id))) {
            toast.warning("organization id invalid")
            return
        }
        dispatch(deleteOrganizationAPI({id: Number(id)}))
            .finally(() => {
                dispatch(getListOfCurrentUserJoinedOrganizationAPI());
                navigate(-1)
            })
    }
    return (
        <Modal open={true}>
            <Box
                component="form"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography className="row col-12 mx-0" variant="h5" component="h2" gutterBottom>
                    <div className="col-12 px-0">do you want to Delete Organization?</div>
                </Typography>
                <div className="d-flex gap-2 justify-content-end mt-3">
                    <Button component={Link} to="/organization" variant="outlined" color="warning">Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleDeleteOrganization}>Delete</Button>
                </div>
            </Box>
        </Modal>
    );
};

export default DeleteOrganization;
