import React, {useEffect, useState} from 'react';
import {Avatar, CircularProgress, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../utils/redux/store";
import {
    EndChatAndGetScoreAPI,
    GetListOfMessagesInRoomByGuestIdAPI,
    RoleplayChatBoxAPI
} from "../../../../utils/redux/store/rolePlaySlice";
import ahuraLogo from '../../../../assets/ahuraLogo.svg'
import {FiUser} from "react-icons/fi";
import {parseAndFormatRolePlay} from "../../../../utils/utils";

const ChatContainer = () => {
    const dispatch = useAppDispatch();
    const [messages, setMessages] = useState([]);
    const [reply, setReply] = useState('');
    const {roomGuid, guestId} = useParams();
    const entities = useAppSelector(state => state.roleplay.entities);
    const loading = useAppSelector(state => state.roleplay.loading);
    const GetListOfMessagesInRoomByGuestIdAPIError = useAppSelector(state => state.roleplay.GetListOfMessagesInRoomByGuestIdAPIError);

    const handleSend = () => {
        if (reply.trim()) {
            dispatch(RoleplayChatBoxAPI({
                guest_id: guestId!,
                room_guid: roomGuid!,
                employee_reply: reply
            })).then((res) => {
                if (!(res as any).error) {
                    dispatch(GetListOfMessagesInRoomByGuestIdAPI({room_guid: roomGuid!, guest_id: guestId!}));
                }
            });
            setReply('');
        }
    };

    const handleInputChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setReply(e.target.value);
    };

    const handleEndChatAndGetScore = () => {
        dispatch(EndChatAndGetScoreAPI({room_guid: roomGuid!, guest_id: guestId!})).then(() => {
            dispatch(GetListOfMessagesInRoomByGuestIdAPI({room_guid: roomGuid!, guest_id: guestId!}));
        });
    };

    useEffect(() => {
        dispatch(GetListOfMessagesInRoomByGuestIdAPI({room_guid: roomGuid!, guest_id: guestId!}));
    }, [dispatch, roomGuid, guestId]);

    if (GetListOfMessagesInRoomByGuestIdAPIError) {
        window.location.href = "/";
        return <CircularProgress/>;
    }

    return (
        <div className="chat-container h-30" style={styles.container}>
            <div className="row mx-0 chat-box" style={styles.chatBox}>
                {entities.map((message: { role: string; content: string; }, index: React.Key | null | undefined) => (
                    <div className="d-flex gap-2 col-12"
                         dir={message.role === "system" ? 'ltr' : 'rtl'}
                         style={message.role === "system" ? {textAlign: 'left'} : {textAlign: 'right'}}>
                        {message.role === "system" && <Avatar src={ahuraLogo}/>}
                        {message.role !== "system" && <Avatar style={{backgroundColor: '#49C02C'}} variant="circular"><FiUser/></Avatar>}
                        <div
                            key={index}
                            style={{
                                ...styles.messageBubble,
                                ...(message.role === "system" ? styles.systemBubble : styles.userBubble),
                                ...(index === 0 ? styles.firstMessage : {})
                            }}
                            dangerouslySetInnerHTML={{__html: index === 0 ? parseAndFormatRolePlay(message.content) : message.content}}
                        >
                        </div>
                    </div>
                ))}
            </div>
            {entities.map((x: {
                content: any;
            }) => x.content).join("").includes("Evaluate my response from 1 to 10 based on strict criteria like creativity, relevance, clarity") ? (
                <div className="text-center">
                    <Typography variant="h2">Your score is: {entities[entities.length - 1].content}/10</Typography>
                </div>
            ) : (
                <div className="chat-input" style={styles.inputContainer}>
                    <input
                        type="text"
                        value={reply}
                        onChange={handleInputChange}
                        placeholder="Type a message..."
                        style={styles.inputField}
                    />
                    <button style={styles.sendButton} onClick={handleSend}>
                        {loading ? <CircularProgress size={20}/> : "Send"}
                    </button>
                    {entities.length > 3 && (
                        <button style={styles.endChatButton} onClick={handleEndChatAndGetScore}>
                            {loading ? <CircularProgress size={20}/> : "Get Score"}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatContainer;

const styles: any = {
    container: {
        justifyContent: 'space-between',
        backgroundColor: '#F0F4F8',  // Light gray background
        padding: '20px'
    },
    chatBox: {
        overflowY: 'auto',
        marginBottom: '10px'
    },
    messageBubble: {
        maxWidth: '70%',
        padding: '10px 15px',
        marginBottom: '10px',
        fontSize: '16px',
        lineHeight: '1.5',
        textAlign: 'left',
        display: 'inline-block',
        direction: 'ltr',
    },
    systemBubble: {
        backgroundColor: '#49C12C',  // Greenish color
        color: '#fff',
        padding: '20px',
        borderBottomLeftRadius: '41.59px',
        borderBottomRightRadius: '41.59px',
        borderTopRightRadius: '41.59px',
        alignSelf: 'flex-start',
    },
    userBubble: {
        backgroundColor: 'unset',  // Light gray for user
        border: '1.3px solid #49C12C',
        borderBottomLeftRadius: '41.59px',
        borderBottomRightRadius: '41.59px',
        borderTopLeftRadius: '41.59px',
        color: '#49C12C',
        fontWeight: 'bold',
    },
    firstMessage: {
        marginTop: '20px',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0',
        backgroundColor: '#fff',
        borderRadius: '30px',
        boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)'
    },
    inputField: {
        flex: 1,
        padding: '10px 15px',
        border: 'none',
        borderRadius: '20px',
        outline: 'none',
        fontSize: '16px',
    },
    sendButton: {
        backgroundColor: '#49C12C',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '20px',
        position: 'relative',
        right: '12px',
        cursor: 'pointer'
    },
    endChatButton: {
        backgroundColor: '#D32F2F',
        color: '#fff',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '20px',
        position: 'relative',
        right: '8px',
        cursor: 'pointer'
    }
};
