import dayjs from "dayjs";

export const unixToDate = (timestamp: number) => dayjs.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');

export const isDevelopment = (process.env.REACT_APP_ENV === "development");
export const isLocal = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
type RolePlayScenario = {
    scenario: string;
    yourRole: string;
    aiRole: string;
};

export function parseAndFormatRolePlay(input: string): string {
    const scenarioMatch = input.match(/the scenario is\s*:\s*(.+?)\s*\.\.\./i);
    const yourRoleMatch = input.match(/I will be role playing as\s*(.+?)\s*\.\.\./i);
    const aiRoleMatch = input.match(/ChatGPT will be role playing as\s*(.+?)\s*\.\.\./i)![1].split(".")[0];

    if (!scenarioMatch || !yourRoleMatch || !aiRoleMatch) {
        throw new Error("Input text is not in the expected format.");
    }

    const scenario: RolePlayScenario = {
        scenario: scenarioMatch[1].trim(),
        yourRole: yourRoleMatch[1].trim(),
        aiRole: aiRoleMatch.trim(),
    };

    return `
Scenario: ${scenario.scenario}<br/><br/>
Your role: ${scenario.yourRole}<br/><br/>
AI role: ${scenario.aiRole}<br/><br/>
Let’s role play!
`.trim().replace(".", "");
}
