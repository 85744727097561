
export function convertLinuxDateToDate(timestamp: number): string {
    const dateObj = new Date(timestamp * 1000); // JavaScript Date object expects milliseconds, so multiply by 1000
    const humanReadableDate = dateObj.toLocaleString(); // Format date as per locale settings
    return humanReadableDate;
}
export function daysRemaining(timestamp: number): number {
    const currentDate = new Date(); // Get the current date
    const targetDate = new Date(timestamp * 1000); // Convert the provided timestamp to Date
    const timeDifference = targetDate.getTime() - currentDate.getTime(); // Get the difference in milliseconds
    const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert the difference to days
    return daysRemaining;
}
