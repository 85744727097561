import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import PaymentSuccess from "../paymentSuccess";
import PaymentFailedFlutterWave from "./paymentfailed-flutterwave";

const FlutterWavePaymentStatus = () => {
    const query = new URLSearchParams(useLocation().search);
    const status = query.get('status');
    const transactionId = query.get('tx_ref');

    useEffect(() => {
        if (status === 'successful') {
            // Handle success, e.g., show success message
            console.log("Payment successful:", transactionId);
        } else {
            // Handle failure, e.g., show error message
            console.log("Payment failed:", transactionId);
        }
    }, [status, transactionId]);
    if (status === 'successful'){
        return <PaymentSuccess/>
    }else{
        return <PaymentFailedFlutterWave/>
    }
};

export default FlutterWavePaymentStatus;
