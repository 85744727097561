import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CircularProgress, IconButton, InputAdornment, Modal, TextField, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {toast} from "react-toastify";
import {acceptInviteToOrganizationAPI, getOrganizationByEmailTokenAPI, rejectInviteToOrganizationAPI} from "../../../utils/redux/store/organizationSlice";
import {IOrganization} from "../../../utils/redux/model/organization.model";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import {useTranslation} from "react-i18next";

const InviteeVerify = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation();
    const {inviteeEmailToken} = useParams()
    const orgFromEmailToken: IOrganization = useAppSelector(state => state.organization.orgFromEmailToken)
    const loading: boolean = useAppSelector(state => state.organization.loading)
    const [isOpen, setIsOpen] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("")

    const handleAcceptInvitation = () => {
        if (password === "") {
            toast.warning("please fill the password.")
            return
        }
        dispatch(acceptInviteToOrganizationAPI({email_token: inviteeEmailToken!, password: password}))
            .finally(() => {
                setIsOpen(false)
                setTimeout(() => {
                    window.location.href = '/login'
                }, 3000)

            })

    }
    const handleRejectInvitation = () => {
        dispatch(rejectInviteToOrganizationAPI({email_token: inviteeEmailToken!}))
    }

    useEffect(() => {
        dispatch(getOrganizationByEmailTokenAPI({email_token: inviteeEmailToken!}))
    }, [])
    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 0,
        p: 4,
    };
    if (!inviteeEmailToken) {
        toast.warning("invalid token.")
        window.location.href = '/login'
    }
    if (loading) {
        return <CircularProgress/>
    }
    return (
        <div className="row mx-0 col-12 mt-5">
            <Card className="col-12 col-lg-5 p-3 mx-auto text-center rounded-3 border-0 shadow-none mt-5">
                <Typography variant="h3">
                    Your account has been created on the Teacher Tools website.
                    <br/>
                    You've been invited to join the organization, <strong>"{orgFromEmailToken?.orgName}"</strong>., on Ahura's Ed Tools application.
                    <br/>
                    Would you like to accept the invitation and set your account password?
                </Typography>
                <div className="col-12  align-items-center mt-3">
                    <Button className="w-167 m-2" variant="contained" color="primary" onClick={() => setIsOpen(true)}>Accept</Button>
                    <Button component={Link} to="/login" className="w-167 hi-50 m-2" variant="contained" color="secondary"
                            onClick={handleRejectInvitation}>Reject</Button>
                </div>
            </Card>
            <Modal open={isOpen}
                   onClose={() => setIsOpen(false)}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <Box sx={style} className="rounded-2 border-0">
                    <div>Please set a password for your account</div>
                    <div className="col-12 mt-3">
                        <TextField
                            className="col-12 mt-2"
                            variant="filled"
                            placeholder={t('login.passwordPlaceholder')}
                            type={showPassword ? "text" : "password"}
                            onChange={(event) => setPassword(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleShowPassword}>
                                            {showPassword ? <MdVisibilityOff/> : <MdVisibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </div>
                    <div className="col-12 text-center mt-3">
                        <Button className="w-167 m-2" variant="contained" color="primary" onClick={handleAcceptInvitation}>Confirm</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default InviteeVerify;
